import { Component, Vue } from "vue-property-decorator";
import { STRAPI_BASE_API_URL } from "@/utils/Constants";
import RequestFilter, { filterToQuery } from "@/models/strapi/RequestFilter";
import { httpStore } from "@/store/typed";
import { Event } from "@/models";

export enum StrapiEventTags {
  EventIndex = "EventIndex",
  EventShow = "EventShow",
}

@Component
export default class StrapiEventMixin extends Vue {
  getEvent(id: any): Promise<Event> {
    const url = `${STRAPI_BASE_API_URL}/events/${id}`;
    return httpStore.request({
      tag: StrapiEventTags.EventShow,
      url: url,
      method: "GET",
    }) as Promise<Event>;
  }

  getEvents(filter: RequestFilter): Promise<Event[]> {
    const url = `${STRAPI_BASE_API_URL}/events?${filterToQuery(filter)}`;
    return httpStore.request({
      tag: StrapiEventTags.EventIndex,
      url: url,
      method: "GET",
    }) as Promise<Event[]>;
  }
}
