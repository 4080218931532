export default interface RequestFilter {
  limit?: number;
  start?: number;
  sort?: string;
  ne?: string;
  lt?: string;
  lte?: string;
  gt?: string;
  gte?: string;
  contains?: string;
  containss?: string;
  in?: string[];
  nin?: string[];
}

export function filterToQuery(filter: any) {
  return Object.keys(filter)
    .map((o) => `_${o}=${filter[o]}`)
    .join("&");
}
