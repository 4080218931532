
import { Vue, Component, Prop } from "vue-property-decorator";
import Event from "@/models/Event";
import { urlFromMedia } from "@/utils/UrlHelper";
import moment from "moment";

@Component({})
export default class EventCard extends Vue {
  @Prop() readonly value!: Event;

  get url() {
    if (!this.value.cover) {
      return "";
    }
    return urlFromMedia(this.value.cover, "medium");
  }

  get startDate() {
    return moment(this.value.starts_at).format("DD MMM YYYY - hh:mm");
  }
  get endDate() {
    return moment(this.value.ends_at).format("DD MMM YYYY - hh:mm");
  }

  get eventDetailRoute() {
    return { name: "event_show", params: { id: this.value.id } };
  }

  get description() {
    return this.value?.description?.slice(0, 140);
  }

  goToDetail() {
    this.$router.push(this.eventDetailRoute);
  }
}
