
import { Component, Mixins, Watch } from "vue-property-decorator";
import { informativeStore } from "@/store/typed";
import EventsMixin from "@/mixins/http/strapi/EventMixin";
import { urlFromMedia } from "@/utils/UrlHelper";
import { safeAsync } from "@/utils/AsyncUtil";
import marked from "marked";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { Event } from "../../models";
import moment from "moment";
import { NoCache } from "@/utils/Decorators";

const tag = "get_single_event";
@Component({})
export default class EventShow extends Mixins(EventsMixin) {
  @RegisterHttp(tag) readonly request!: RequestData;

  private item: Event | null = null;

  private error = false;

  mounted() {
    this.load();
  }

  get startDate() {
    if (!this.item) {
      return "";
    }
    return moment(this.item.starts_at).format("DD MMM YYYY - hh:mm");
  }
  get endDate() {
    if (!this.item) {
      return "";
    }
    return moment(this.item.ends_at).format("DD MMM YYYY - hh:mm");
  }
  get id(): string | number {
    return this.$route.params.id;
  }

  @Watch("id")
  onIdChanhed(value: string) {
    this.load();
  }

  get sanitizedMarkdown(): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.htmlContent, "text/html");
    const images = doc.getElementsByTagName("img");
    for (var i = 0; i < images.length; i++) {
      images[i].classList.add("img-fluid");
    }
    return doc.body.innerHTML;
  }

  goToNext() {
    this.$router.push({
      name: "event_show",
      params: { id: this.nextEvent.id },
    });
  }

  goToPrevious() {
    this.$router.push({
      name: "event_show",
      params: { id: this.previousEvent.id },
    });
  }

  async load() {
    const [data, errors] = await safeAsync(this.getEvent(this.id));
    if (!errors) {
      this.error = false;
      this.item = data;
      window.scroll({ top: 0, behavior: "smooth" });
    } else {
      this.error = true;
    }
  }

  @NoCache
  get nextEvent() {
    const index = this.events.findIndex(
      (o: Event) => o.id.toString() === this.id.toString()
    );
    return this.events[index + 1];
  }

  @NoCache
  get previousEvent() {
    const index = this.events.findIndex(
      (o: Event) => o.id.toString() === this.id.toString()
    );
    return this.events[index - 1];
  }

  get htmlContent() {
    return this.item?.description ? marked(this.item.description) : "";
  }

  get coverImageUrl() {
    return this.item ? urlFromMedia(this.item.cover, "large") : "";
  }

  get events(): Event[] {
    return informativeStore?.events;
  }

  get loading() {
    return this.request?.loading;
  }
}
